.mespTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}
.mespContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mespInnerContainer{
    padding: 20px;
    justify-content: center;
    width: 90vw;
}

.mespHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.mespButtonContainer{
    display: flex;
    justify-content: right;
    margin-top: 30px;
    gap: 20px;
}

.mespQuestionControl{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.mespSplit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.selectionButton{

}

@media screen and (max-width: 800px) {
    .mespSplit{
        flex-direction: column;
    }
    
}