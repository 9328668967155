.floatingChatBot{
    position:fixed;
    bottom:20px;
    right:20px;
    z-index:1000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.chatbot-button{
    background-color: #1B4965;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 20px;
    padding: 10px;
    margin-right: 20px;
    text-align: center;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
}

.react-chatbot-kit-chat-container {
    max-width: 30vw;
    background-color: #D9D9D9;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    /* padding: 20px; */
    /* max-height: 55vh; */
    
}

.react-chatbot-kit-chat-inner-container {
    /* padding: 20px; */
}

.react-chatbot-kit-chat-header {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #376B7E;
    color: white;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    gap: 10px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;   
}

.react-chatbot-kit-chat-input-container {

}

.react-chatbot-kit-chat-message-container {
    overflow-y: scroll;
    max-height: 40vh;
    /* style scrollbar */
    scrollbar-width: thin;
    scrollbar-color: #376B7E #D9D9D9;   
    padding: 20px;
    padding-bottom: 0px;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
    width: 12px;
}

.react-chatbot-kit-chat-input {
    border: none;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

.react-chatbot-kit-chat-input-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.react-chatbot-kit-chat-input::placeholder {
    color: #376B7E;
}

.react-chatbot-kit-chat-btn-send {
    width: 100px;
    height: 50px;
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    
}

.react-chatbot-kit-chat-btn-send-icon {
    fill: #fff;
    width: 20px;
}

.react-chatbot-kit-chat-bot-message-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
    
}

.react-chatbot-kit-chat-bot-avatar-letter {
    /* width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: #1B4965;
    color: white; */
}

.react-chatbot-kit-chat-bot-avatar{
    
}

.react-chatbot-kit-chat-bot-message {
    color: white;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    background-color: #376B7E;
    margin-top: 10px;
    margin-bottom: 10px;
}


.react-chatbot-kit-chat-bot-message-arrow {
    
}

.react-chatbot-kit-chat-bot-loading-icon-container {
}

.chatbot-loader-container {
}

#chatbot-loader #chatbot-loader-dot1 {
}

#chatbot-loader #chatbot-loader-dot2 {
}

#chatbot-loader #chatbot-loader-dot3 {
}

.react-chatbot-kit-error {
}

.react-chatbot-kit-error-container {
}

.react-chatbot-kit-error-header {
}

.react-chatbot-kit-error-docs {
}

.react-chatbot-kit-user-avatar-container{
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: white;
    text-align: center;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.react-chatbot-kit-user-avatar-icon{
    height: 20px;
    width: 20px;
    padding :3px;
}


.react-chatbot-kit-user-chat-message-container{
    display: flex;
    align-items: flex-end;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.react-chatbot-kit-user-chat-message {
    color: white;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    background-color: #1B4965;
}

@media screen and (max-width:800px) {
    .react-chatbot-kit-chat-container {
        max-width: 80vw;
        max-height: 100vh;
    }
}
