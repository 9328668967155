.bot-avatar {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    background-color: white;
    color: black;
}

.react-chatbot-kit-chat-bot-icon {
    height: 20px;
    width: 20px;
    padding: 3px;
}