.apContainer{
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.apInnerContainer{
    width: 90vw;
}


.apTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    margin-bottom: 0px;
}

.apSubtitle{
    font-size: 30px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    margin-top: 0px;
}

.apButtonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-top: 70px;
    width: 100%;
    margin-bottom: 100px;
}

#apSubmitButton{
    text-decoration: none;
    background-color: #D5C67A;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: black;
    min-width: 150px;
    text-align: center;
}

.apFormContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60vw;
}

.apForms{
    display: flex;
    align-items: center;
    justify-content: center;
}

.apTextField{
    width: 25vw;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #000;
    font-size: 20px;
    padding-left: 10px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.apFormTitle{
    font-size: 20px;
    text-align: center;
    text-align: left;
    margin-bottom: 0px;
    width: 100%;
    padding-left: 10px;
}

.apSubButtonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.apSubButton{
    margin-top: 0px;
}

.apFormFloat{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
}

@media screen and (max-width:1000px) {
    .apTitle{
        font-size: 30px;
        margin-bottom: 0px;
        line-height: normal;
    }
    .apSubtitle{
        font-size: 20px;
        margin-top: 0px;
        line-height: normal;
    }
    .apContainer{
        margin-top: 0px;
    }
    .apForms{
        width: 100%;
    }
    .apFormContainer{
        width: 100%;
    }
    #apSubmitButton{
        width: 100%;
    }

    .apFormFloat,.apIndyComponents{
        flex-direction: column;
        width: 100%;
    }

    .apTextField{
        width: 100%;
    }
}