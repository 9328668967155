.options-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}
  
.option-button {
    margin: 5px;
    border-radius: 25px;
    padding: 8px;
    border-color: #1B4965;
    background: white;
    text-align: center;
}
  