.amdpContainer{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.amdpInnerContainer{
    width: 90vw;
}
.amdpTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.amdpMemberInfoSplit{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.amdpMemberInfoLeft{
    width: 45%;
}
.amdpMemberInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.amdpMemberInfoRight{
    width: 45%;
}


.amdpMemberInfoTitle{

}
.amdpMemberField{
    width: 70%;
}

.residentialAddress{
    width: 100%;
    margin-top: 50px;
}

.amdpButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
    margin-bottom: 100px;
}

.amdpButton{
    min-width: 300px;
    height: 50px;
    font-size: 20px;
    background-color: #1B4965;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

}


.amdpBookings{
    width: 100%;
    display: flex;
    /* allow scroll hide scrollbar */
    overflow-x: auto;
    overflow-y: hidden;
    /* hide scrollbar for IE, Edge and Firefox */
    scrollbar-width: none;
    /* hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    padding: 20px;
    padding-left: 0px;
    padding-right: 0px;
}
.amdpBookings::-webkit-scrollbar {
    display: none;
}

.amdpBooking{
    min-width: 300px;
    background-color: #1B4965;
    color: wite;
    margin-right: 20px;
    padding: 20px;

    /* add shadow */
    -webkit-box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.75);
    border-radius: 20px;
    
}

.amdpBookingInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.amdpBookingInfoTitle{
    color: white;
    font-size: 12px;
}
.amdpBookingInfoValue{
    color: white;
    font-size: 12px;
}

.amdpBookingButton{
    height: 50px;
    font-size: 20px;
    background-color:#D5C67A;
    border-radius: 25px;
    cursor: pointer;
    padding: 10px 30px;
    margin-top: 30px;
}



@media screen and (max-width:1000px) {
    .amdpTitle{
        font-size: 30px;
        line-height: normal;
    }
    .amdpMemberInfoSplit{
        flex-direction: column;
    }
    .amdpMemberInfoLeft, .amdpMemberInfoRight, .amdpMemberField,.amdpMemberInfo{
        width: 100%;
    }
    .amdpMemberInfo{
        flex-direction: column;
        align-items: flex-start;
    }
    .amdpMemberField{
        margin-top: 20px;
        width: 90vw;
    }

    .amdpMemberInfoTitle{
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .amdpBooking{
        max-width: 70vw;
        min-width: none;
    }

    .amdpBookingInfoTitle,.amdpBookingInfoValue{
        font-size: 12px;
    }

    .amdpButtonContainer{
        flex-direction: column;
        gap: 10px;
    }

}