.aepTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.aepContainer{
    /* margin-top: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 40px; */
    width: 100vw;
}

.aepSplit{
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.eventContainer{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    gap: 30px;
    width: 90vw;
}

.eventsThumbnail{
    width:  25vw;
    height: auto;
    background-color: #D5C67A;
}

.eventDetails{
    margin-left: 20px;
}

.eventThumbnailImage{
    width: 25vw;
    height:  25vw;
    object-fit: cover;
}

.eventEditButtonContainer{
    display: flex;
    justify-content: right;
    margin-right: 20px;
}

#newEventButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 25px;

}

.eventEditButton{
    border: none;
    background-color: #1B4965;
    color: white;
    padding: 10px 10px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    min-width: 200px;
    
}

@media screen and (max-width: 1000px) {
    .eventContainer{
        flex-direction: column;
    }

    .eventThumbnailImage{
        width: 250px;
        height: 250px;
    }
    .eventsThumbnail{
        width: 250px;
    }
    .aepTitle{
        font-size: 30px;
        line-height: 50px;
    }
}