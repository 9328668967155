.abdpContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.abdpInnerContainer{
    width: 90vw;
}
.abdpTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.adbpSplit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* margin-top: 50px; */
    width: 90vw;
}

.adbpSplitLeft{
    width: 45%;
}
.adbpSplitRight{
    width: 45%;
}


.abdpSubtitle{
    font-size: 30px;
    text-align: center;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin-bottom: 0px;
}

.abdpDetails{
    padding: 20px;
    border: 2px solid #000;
}
.abdpName{
    font-size: 30px;
    /* line-height: 50px; */
    text-align: center;
    color: #000;
    /* text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
}
.abdpLicense{
    font-size: 20px;
    /* line-height: 50px; */
    text-align: center;
    color: #000;
    /* text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
}
.abdpStats{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.abdpSubDetails{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.abdpSubDetails label,.abdpLabel{
    font-size: 20px;
}

/* select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #000;
} */

select{
    background-color: white;
}

.abdpInput,.adbpSelection{
    width: 60%;
    border: 2px solid #000;
    border-radius: 10px;
    box-sizing: border-box;
    /* margin-top: 6px;
    margin-bottom: 16px; */
    padding: 30px;
    font-size: 20px;
    color: #000;
}


.abdpBookingRemarks{
    flex-direction: column;
    display: flex;
    margin-top: 50px;
}
.abdpLabel{
    font-size: 20px;
    margin-bottom: 0px;
}
.bookingText{
    font-size: 20px;
    padding: 15px;
    resize: none;
    height: 200px;
    border: 2px solid #000;
    border-radius: 10px;
}


#cancelButton{
    color: black;
    text-decoration: none;
    font-size: 20px;
    border-radius: 10px;
}

.abdpButtonContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 20px;
}

.abdpButton{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    background-color: #16BB26;
    color: white;
    border: none;
}

.abdpButtonContainer1{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 100px;
}
.abdpDeleteButton{
    width: 200px;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    background-color: #C60000;
    color: white;
    border: none;
}
.abdpSaveButton{
    width: 200px;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    background-color: #D5C67A;
    color: white;
    border: none;
    margin-left: 30px;
}

@media screen and (max-width:1200px) {
    .abdpTitle{
        font-size: 30px;
    }

    .adbpSplit{
        flex-direction: column;
    }

    .abdpDetails,.adbpSplitLeft,.adbpSplitRight{
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;   
        width: 100%;
    }
    .abdpSubDetails{
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }
    .abdpDetails{
        width: 100%;
    }
    .abdpSubtitle{
        font-size: 20px;
    }
    .abdpButtonContainer1{
        flex-direction: column;
    }
    .abdpDeleteButton,.abdpSaveButton{
        margin: 0px;
        width: 100%;
        margin-top: 10px;
    }

    .abdpInput,.adbpSelection, .abdpInput input, input {
        width: 100%;
    }

    .abdpButtonContainer,.abdpButtonContainer1{
        width: 100%;
    }
}