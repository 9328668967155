.anepImageContainer{
    justify-content: center;
    padding: 30px;
    height: 200px;
    border-radius: 10px;
    border: 2px solid #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;   
}


.anepImageContainer .anepLabel,.anepUploadContent{
    text-align: center;
}

.anepUploadContent{
    height: 50px;
    border: none;
    display: flex;
    text-align: center;
}

.anepContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.anepInnerContainer{
    /* padding: 20px; */
    justify-content: center;
    width: 90vw;
}
.anepLabel{
    font-size: 20px;
    width: 100%;
}

.anepFieldContainer{
    /* padding: 10px; */
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}


.anepSplitContainer{
    display: flex;
    margin-top: 30px;
    gap: 50px;
}
.anepSplit{
    align-items: left;
    width: 50%;
    /* padding: 30px; */
}
.anepSplit2{
    align-items: right;
    width: 50%;
    /* padding: 30px; */
}

.anepTypeSelection{
    /* width: 20vw;
    height: 20px;
    margin-top: 6px;
    padding: 20px; */
    font-size: 20px;
}

.anepInput{
    border-radius: 10px;
}

.anepHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.anepTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

#newEventButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 25px;
}

.eventDescription{
    width: 100%;
}

.anepQuestionContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.anepQuestionLabelContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media screen and (max-width:1000px) {
    .anepTitle{
        font-size: 30px;

    }
    .anepSplitContainer{
        flex-direction: column;
    }
    .anepSplit,.anepSplit2{
        width: 100%;
    }
    .anepFieldContainer{
        padding:0px;
        margin-top: 10px;
        width: 100%;
    }


}