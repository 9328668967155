.mhpTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.mhpContainer{
    /* margin-top: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mhpInnerContainer{
    width: 90vw;
}

.mhpSection{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.mhpInnerSection{
    background-color: #D9D9D9;
    border-radius: 20px;
    width: 95vw;
    display: flex;
    align-items: center;
}

.mhpFBText{
    padding: 50px;
    justify-content: center;
    width: 100%;
    color: #1B4965;
    padding-top: 0;
    padding-bottom: 0;
}

.mhpButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-top: 200px;
}

.mhpFBText a{
    text-decoration: none;
    color: black;
    background-color: #D5C67A;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
}

.mhpFBText p{
    width: 100%;
    font-size: 23px;
    color: #1B4965;
}

@media screen and (max-width: 800px){
    .mhpFBText{
        padding-top: 10px;
        width: 90%;
    }
    .mhpInnerSection{
        flex-direction: column;
    }   

    .mhpInnerSection img{
        width: 100%;
        border-radius: 20px;
    }

    .mhpButton{
        margin-top: 50px;
    }
    .mhpTitle{
        font-size: 25px;
        line-height: normal;
    }
    .forms h6{
        font-size: 20px;
        padding: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}