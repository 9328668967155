.aapContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.aapInnerContainer{
    padding: 20px;
    justify-content: center;
    width: 90vw;
}
.aapTitle {
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}


.aapSplit{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
}
.aapLeft{
    width: 45%;
}
.aapRight{
    width: 45%;
}

.aapRight p{
    font-size: 20px;
    margin-top: 10px;
    font-weight: bold;
}

.aapDocument p{
    font-size: 20px;
    margin-top: 10px;
    font-weight: 200;
}

.aapDocument p:hover{
    cursor: pointer;
    text-decoration: underline;
}

.aapForm p {
    font-size: 20px;
    margin-top: 10px;
    font-weight: 200;
    margin-bottom: 2px;
}

.aapInput{
    width: 100%;
}

.aapButtonContainer{

}
.aapButton{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: #1B4965;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border: none;
    margin-top: 20px;
}

@media screen and (max-width:1000px) {
    .aapSplit{
        flex-direction: column;
        gap: 0px;
    }
    .aapRight, .aapLeft{
        width: 100%;
    }
    .aapTitle{
        text-align: center;
        font-size: 30px;
        line-height: normal;
    }
}