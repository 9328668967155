.epContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.epInnerContainer{
    width: 90vw;
}

.eventContainer{
    margin-bottom: 100px;
}

.eventHeaderImage{
    width: 100vw;
    height: 400px;
    object-fit: cover;

}

@media screen and (max-width:1000px) {
    .epHeaderImage{
        width: 100vw;
        height: 300px;
        object-fit: cover;
    }
}