@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: bold;
    src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('opentype');
}

body {
  margin: 0;
  width: 100vw;
  font-family: "Poppins" ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}