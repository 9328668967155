.mppContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mppInnerContainer{
    width: 90vw
}

.mppTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}


#cancelButton{
    color: black;
    text-decoration: none;
    font-size: 20px;
    border-radius: 10px;
}


.mppSplit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#profileImage{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 2px solid #000;
    object-fit: cover;
}


.mppTopProfile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
}
.mppName{
    font-size: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
}
.mppUserTitle{
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
}
.mppStats{
    font-size: 30px;
}

.mppRecentFlights{
    width: 100%;
}

.mppSubtitle{
    font-size: 30px;
    text-align: center;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin-bottom: 0px;
}

.mppRecentFlights{
    margin-top: 50px;
    width: 100%;
    margin-bottom: 100px;
}

.mppRecentFlightsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    overflow-x: scroll;

    /* hide scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.mppRecentFlightsContainer::-webkit-scrollbar {
    display: none;
}

.mppFlight{
    background-color: #1B4965;
    width: 300px;
    padding: 10px;
}

.mppDataRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 290px;
}
.mppDataLabel{
    color: white;
}
.mppDataValue{
color: white;
}

.mppCommunityPostContainer{
    margin-bottom: 100px;
}

@media screen and (max-width: 1000px) {
    .mppTitle{
        font-size: 30px;
        line-height: normal;
    }
    .mppName{
        font-size: 30px;
        margin-top: 10px;
    }
    .mppUserTitle{
        font-size: 20px;
    }
    .mppStats{
        font-size: 20px;
    }
    #profileImage{
        width: 200px;
        height: 200px;
        object-fit: cover;
    }
}