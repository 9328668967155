.mcpTitle{
    font-size: 50px;
}

.mcpPostContainer{
    width: 100%;
    margin-bottom: 20px;
    
}
.mcpPost{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    background-color: #D9D9D9;
    /* border-top-left-radius: 200px; */
}
.mcpPostHeader{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 95%;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: space-between;

}

.mcpProfileAndInfo{
    display: flex;
    align-items: center;
}


.options{
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #1B4965;
    gap: 50px;
    width: 100vw;
    position: fixed;
    padding-bottom: 100px;
  }

.mcpImg{
    margin-right: 10px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;

}
.mcpPostHeaderInfo{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 10px;
}

.mcpPostHeaderInfo p, .mcpPostHeaderInfo h1{
    margin: 0;
    padding: 0;
}

.mcpPostContents{
    width: 90%;
    padding: 10px;
}

.mcpImagePost{
    width: 90%;
    height: 600px;
    object-fit: cover;
    border-radius: 30px;
}

.mcpDescription{
    font-size: 23px;
}

.mcpCommentSplit{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.mcpCommentsContainer{
    width: 90%;
}

.mcpCommentSection{
    /* height: 150px; */
    font-size: 25px;

}

.mcpCommentImg{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.mcpCommentHeader{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    margin-left: 20px;
    gap: 10px;
}

.mcpComment{
    border-left-width: 3px solid #000;
    border-left-style: solid;
    margin-top: 10px;
}


.mcpUpcomingEventsContainer{
    display:flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.mcpCommentHeader p{
    padding: 10px;
    margin: 0;
    padding-top: 0px;
    padding-bottom: 0px;
}

.mcpCommentHeader{
align-items: flex-start;
}


@media screen and (max-width:1000px) {
    .mhpTitle{
        font-size: 30px;
        
    }
    .mcpImg{
        height: 50px;
        width: 50px;
    }
    .mcpPostHeaderInfo h1, .mcpPostHeaderInfo p{
        font-size: 20px;
    }
    .mcpImagePost{
        height: 300px;
        width: 300px;
        object-fit: cover;
    }
    .mcpPostContents h1{
        font-size: 20px;
    }
    .mcpPostContents p{
        font-size: 15px;
    }

    .mcpCommentImg{
        height: 30px;
        width: 30px;
    }
    .mcpCommentHeader p{
        font-size: 15px;
    }
}