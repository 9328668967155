.anfpTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.anfpContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.anfpInnerContainer{
    width: 90vw;
}


.anfpFormContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.anfpSplitLeft{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.anfpSplitRight{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.anfpInformation{
    width: 30%;
}
.anfpInput{
    width: 70%;
}

.anfpForm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}

.anfpButton{
    height: 50px;
    background: #D5C67A;
    border-radius: 10px;
    color: black;
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    min-width: 200px;
}

.anfpButtonContainer1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 100px;
}

@media screen and (max-width:1000px) {
    .anfpTitle, #anfpTitle{
        font-size: 30px;
        line-height: 50px;
    }
    .anfpContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .anfpInnerContainer{
        width: 90vw;
    }
    .anfpForm{
        flex-direction: column;
    }
    .anfpFormContainer{
        flex-direction: column;
    }
    .anfpSplitLeft, .anfpSplitRight{
        width: 100%;
    }
    .anfpButtonContainer{
        flex-direction: column;
    }
    .anfpInput{
        width: 100%;
    }
    .anfpInformation{
        width: 100%;
    }
    .anfpButton{
        min-width: 100%;
    }
}