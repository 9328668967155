.anfpTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.anfpContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.anfpInnerContainer{
    width: 90vw;
}


.anfpFormContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.anfpSplitLeft{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.anfpSplitRight{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}


.anfpInformation{
    width: 30%;
    text-align: left;
}
.anfpInput{
    width: 70%;
}

.anfpForm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}

.anfpButton{
    height: 50px;
    background: #D5C67A;
    border-radius: 10px;
    color: black;
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    min-width: 200px;
}

.anfpButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 100px;
}

.anepImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.aefpImageContainer{
    padding: 0px;
    height: 50vh;
}

.aefpImageContainer:hover{
    opacity: 0.5;
    background-color: black;
    cursor: pointer;
}

.aefpImageContainer{

}





.aefpSplitContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}

.aefpBlockTime{
    text-decoration: none;
    color: black;
    font-size: 18px;
    border: none;
    cursor: pointer;
    color: black;

}


/* Modal */
.aefpModal{
    position: fixed;
    z-index: 1;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}
.aefpModalContainer{
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    margin-bottom: 100px;
}
.aefpModalHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}
.aefpModalTitle{
    font-size: 40px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}
.aefpModalClose{
    color: #000;
    float: right;
    font-size: 28px;
    text-decoration: none;
    font-weight: bold;
}
.aefpModalBody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}
.aefpModalForm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}
.aefpModalInformation{
    width: 30%;
    text-align: left;
}
.aefpModalInput{
    width: 70%;
}
.aefpModalFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}
.aefpModalButton{
    height: 50px;
    background: #D5C67A;
    border-radius: 10px;
    color: black;
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    min-width: 200px;
}

.aefpModalRemarks{
    width: 100%;
    height: 200px;
    border-radius: 10px;
    border: 2px solid #000;
    padding: 10px;
    font-size: 18px;
    resize: none;
}

.aefpRemarksContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}

.aefpModalUploadForm{
    border: 2px solid #000;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width:1000px) {
    .anfpTitle, #anfpTitle{
        font-size: 30px;
        line-height: 50px;
    }
    .anfpContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .anfpInnerContainer{
        width: 90vw;
    }
    .anfpForm{
        flex-direction: column;
    }
    .anfpFormContainer{
        flex-direction: column;
    }
    .anfpSplitLeft, .anfpSplitRight{
        width: 100%;
    }
    .anfpButtonContainer{
        flex-direction: column;
    }
    .anfpInput{
        width: 100%;
    }
    .anfpInformation{
        width: 100%;
    }
    .anfpButton,.aefpModalInformation,.aefpModalInput{
        min-width: 100%;
    }

    .aefpModalForm{
        flex-direction: column;
    }
    .aefpModalFooter{
        flex-direction: column;
        width: 100%;
    }
    .aefpModalButton{
        width: 100%;
    }
}