.ampTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.ampContainer{
    /* margin-top: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ampInnerContainer{
    width: 90vw;
}

#newMemberButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 25px;
}

.ampSplit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.ampTableHeader{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 25px;
    background-color: #1B4965;
    padding: 10px;
    color: white;
}

.ampTableHeaderItem{
    /* margin: 0 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.ampTableRow{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: flex-start;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.ampTableItem{
    /* margin: 0 0px 0px 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.ampTable{
    margin-bottom: 100px;
}


#ampViewButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    background-color: #D5C67A ;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
}

#ampViewButton:hover{
    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.ampSmallTableContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ampSmallTable{
    margin-bottom: 20px;
    width: 90%;
    background-color: #1B4965;
    padding: 20px;
}

.ampSmallTableItem{
    color: white;
    font-size: 12px;
}

.ampSmallTableRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.ampSmallTableContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:1200px) {
    .ampActionButton{
        justify-content: center;
    }
    .ampTitle{
        font-size: 30px;
        line-height: normal;
        text-align: center;
        color: #1B4965;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        text-align: left;
    }
}