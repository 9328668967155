.acppTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.acppContainer{
    /* margin-top: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.acppInnerContainer{
    width: 90vw;
}

.spTable{
    margin-bottom: 100px;
}

.spTableHeader{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 25px;
    background-color: #1B4965;
    padding: 10px;
    color: white;
}

.spTableHeaderItem{
    /* margin: 0 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.spTableRow{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    padding-top: 0px;
    padding-bottom:0px;
    color: white;
    background-color: #1B4965;
}

#spViewButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    /* margin: 4px 2px; */
    background-color: #D5C67A ;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
}

.acppConfigContainer{}
.acppButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.acppPostStatus{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
}

.acppButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    /* margin: 4px 2px; */
    background-color: #D5C67A ;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
    
}

.acppConfigContainer textarea{
    font-size: 25px;
}


.spSmallTable{
    margin-bottom: 20px;
    width: 90%;
    background-color: #1B4965;
    padding: 20px;
}

.spSmallTableItem{
    color: white;
    font-size: 12px;
}

.spSmallTableRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.spSmallTableContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.spDataContainer{
    margin: 0px;
    padding: 0px;
}
.spDatarow{
    margin: 0px;
    padding: 0px;
    background-color: #1B4965;
    padding: 10px;
    border-bottom: 1px solid #fff;
}
.spDataHeader{
    margin: 0px;
    padding: 0px;
    color: white;
}
.spDescription{
    margin: 0px;
    padding: 0px;
    color: white;
}

.spDataButtonContainer{
    display: flex;
    align-items: right;
    justify-content: right;
    width: 100%;
}
.spDataDownloadButton{
    display: flex;
    align-items: center;
    gap: 5px;
}

.spButtonText{
    color: white;
    margin: 0px;
    padding: 0px; 
}

.acppNewButton{
    border: none;
    color: black;
    text-decoration: none;
    
}

.acppSplit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}

.acppSplitForm{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.acppField{
    border: 2px solid #000;
    border-radius: 5px;
    padding: 5px;
    width: 50vw;
}

@media screen and (max-width:1000px) {
    .acppTitle{
        font-size: 30px;
    }
    .spActionButton{
        width: 100%;
        display: flex;
        margin-top: 10px;
        justify-content: center;
        align-items: center;

    }
    #mbpViewButton{

        text-align: center;
    }   

    .acppSplitForm{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .acppField{
        width: 100%;
    }

    .acppLabel{
        text-align: left;
        width: 100%;
    }
}