.Aucontainer img{  
    width: 100vw;
    height: 400px;
    object-fit: cover;

}

.Aucontainer {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.auInnerContainer{
    width: 90vw;
}
.Auparagraph{
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-top: 30px;
}
.Autitle{
    font-size: 50px;
    line-height: 100px;
    width: 90vw;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    padding: 30px
}

.Splitimage img{
    width: 45vw;
}


.Splitimage{
    /* width: 90vw; */
    display: flex;
    text-align: left;
    padding: 20px;
    gap:50px;
    align-items: center;
}

.Autext{
    text-align: left;
    /* padding: 60px */
}

.Splitimage2 img{
    width: 50vw;
}

.Splitimage2{
    display: flex;
    text-align: left;
    padding: 20px;
    gap:50px;
    align-items: center;
}

.auAircraftOperated{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}
.auInnerLeftDiv{
    width: 50%;
}


.Aircraftcontainer{
    border: none;
    background-color: #4CB5AE;
    border-radius: 10px;
    width: 40vw;
    display: flex;
    align-items: center;
    color: black;
    padding: 20px;
    text-align: center;
}


.Aircraftimage{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.Aircraftimage img{
    width: 40vw;
    display: flex;
    align-items: right
}


.Autext3{
    align-items: center;
    padding: 60px
}

.Autitle2{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    padding: 30px
}

.Autitle3{
    font-size: 30px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: right;
    padding: 80px;
}

.splitDiv{
    display:flex;
    flex-direction: row;
    background-color: #D9D9D9;
    align-items: center;
}

.splitDiv img{
    width: 50vw;
}

.splitTextDiv{
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
}

.innerSplitDiv p{
    font-size: 20px;
    color: black;
}

.innerSplitDiv{
    /* margin-left: 20px; */
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 10px;
    color: black;
    align-items: center;
}

.aircraftName{
    margin: 20px;
    font-size: 30px;
}


.Autext4{
    align-items: center;
    /* padding: 60px; */
    width: 45vw;
}

.Autitle4{
    font-size: 30px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    padding: 150px;
}

.Autext5{
    text-align: center;
}

.Autitle5{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    padding: 30px;
}


.Contactus{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Cutitle{
    font-size: 32px;
}

.Cutitle,.Contactus{

    margin: 0;
    /* padding: 100px; */
}

#auMap{
    width: 50%;
}



@media screen and (max-width:1000px) {
    .Autitle{
        font-size: 40px;
        line-height: normal;
        /* text-align: center; */
        color: #1B4965;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        text-align: left;
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .Aucontainer img {
        width: 100vw;
        height: 300px;
        object-fit: cover;
    }

    .Splitimage{
        display: flex;
        text-align: left;
        padding: 20px;
        gap:50px;
        align-items: center;
        flex-direction: column;
    }
    .Splitimage p{
        padding-top: 0px;
        margin-top: 0px;
    }
    .Autext,.Autext2{
        text-align: left;
        padding: 20px;
        padding-top: 0px;
        margin-top: 0px;
    }

    .splitDiv img{
        width: 100vw;
        height: 300px;
        object-fit: cover;
    }

    .Aircraftimage{
        width: 100vw;
    }
    .auAircraftOperated{
        flex-direction: column;
    }
    .auInnerLeftDiv,.Aircraftcontainer{
        width: 90%;
    }

    .splitTextDiv{
        width: 100vw;
        flex-direction: column;
    }

    #auFlipgrid{
        flex-flow: wrap;
        flex-direction: column-reverse;
    }

    .splitInnerDiv p {
        font-size: 20px;
        color: black;
        width: 90vw;
        text-align: center;
    }

    .Autext4{
        width: 90vw;
        margin-top: 0px;
    }

    .Contactus{
        flex-direction: column-reverse;
        justify-content: flex-start;
        width: 100%;
    }
    #auMap{
        width: 90vw;
    }

    .auInnerLeftDiv{
        width: 100%;
    }

    .auAircraftOperated img{
        width: 100%;
    }
    .innerContactUs{
        width: 100%;
    }
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10;
    max-width: 1000px;
    /* align-items: center; */
}

.AuButton{
    /* width: 50%; */
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 15px;
    background-color: #16BB26;
    color: white;
    border: none;
    cursor: pointer;
}