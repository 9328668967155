.abpTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.abpContainer{
    /* margin-top: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.abpInnerContainer{
    width: 90vw;
}


.abpTableHeader{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 25px;
    background-color: #1B4965;
    padding: 10px;
    color: white;
}

.abpTableHeaderItem{
    /* margin: 0 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.abpTableRow{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.abpTableItem{
    /* margin: 0 0px 0px 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.abpTable{
    margin-bottom: 100px;
}


#abpViewButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    background-color: #D5C67A ;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
}

#abpViewButton:hover{
    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.abpSubtitle{
    font-size: 30px;
    margin-bottom: 0px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.abpSmallTable{
    margin-bottom: 20px;
    width: 90%;
    background-color: #1B4965;
    padding: 20px;
}

.abpSmallTableItem{
    color: white;
    font-size: 12px;
}

.abpSmallTableRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.abpSmallTableContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:1200px) {
    .abpTitle{
        font-size: 30px;
        line-height: normal;
        text-align: center;
        color: #1B4965;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        text-align: left;
        margin-left: 0px;
        padding-left: 0px;
    }
    .abpSubtitle{
        font-size: 20px;
        margin-left: 0px;
        padding-left: 0px;
    }
}