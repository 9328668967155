.epTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.flyForAnyEvent{
    margin-top: 20px;
    text-align: left;
    /* padding: 50px; */
    /* font-size: x-large; */
    justify-content: center;
    align-items: center;
    display: flex;
}
.epHeaderImage{
        width: 100vw;
        height: 400px;
        object-fit: cover;
    
}
.epInnerDiv{
    width: 90vw;
}

.givingBackToTheCommunity{
    text-align: left;
    /* padding: 50px; */
    align-items: center;
    font-size: x-large;
}

.epStory{
    display: flex;
    text-align: left;
    align-items: flex-start;
}



.story1{
    /* padding: 10px; */
    text-align: left;
    line-height: 30px;
    width: 50vw;
    /* gap: 10px; */
}


.epArticle{
    text-align: right;
} 

.epArticle img{
    /* padding: 10px; */
    width:50vw;
}

.epStory2{
    display: flex;
    text-align: left;
    align-items: center;
    gap: 20px;
}

.epStory3{
    display: flex;
    margin-top: 20px;
    text-align: left;
    align-items: center;
    gap: 20px;
}

.epStory2 img{
    width: 45vw;
}
.epStory3 img{
    width: 45vw;
}

@media screen and (max-width:1000px) {
    .epHeaderImage{
        width: 100vw;
        height: 300px;
        object-fit: cover;
    }

    .epTitle{
        font-size: 40px;
        line-height: normal;
        text-align: center;
        color: #1B4965;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        text-align: left;
    }
    .epStory{
        flex-direction: column;
    }
    .story1{
        padding: 10px;
        text-align: left;
        line-height: normal;
        width: 100%;
    }
    .epArticle img{
        width: 100%;
    }

    .epStory2{
        flex-direction:column;
        width: 100%;
    }
    .epStory3{
        flex-direction: column-reverse;
        width: 100%;
    }
    .epStory2 img{
        width: 100%;
    }
    .epStory3 img{
        width: 100%;
    }
}