.mbpSplit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#newBookingButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 25px;
}


.mbpTableHeader{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 25px;
    background-color: #1B4965;
    padding: 10px;
    color: white;
}

.mbpTableHeaderItem{
    /* margin: 0 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
    width: 30%;
}

.mbpTableRow{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.mbpTableItem{
    /* margin: 0 0px 0px 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
    width: 30%;
}

.mbpTable{
    margin-bottom: 100px;
}


#mbpViewButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    background-color: #D5C67A ;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
}

#mbpViewButton:hover{
    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.mbpSmallTable{
    margin-bottom: 20px;
    width: 90%;
    background-color: #1B4965;
    padding: 20px;
}

.mbpSmallItem{
    color: white;
    font-size: 12px;
}

.mbpSmallRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.mbpSmallTableContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.mhpTitle{
    font-size: 50px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

@media screen and (max-width:1200px) {
    .mbpTableHeaderItem{
        width: 100%;
    }
    .mbpTableItem{
        width: 100%;
    }
    .mbpTableRow{
        flex-direction: column;
    }
    #newBookingButton{
        /* margin: 10px 0px; */
        size: 10px;
    }

    .mbpActionButton{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .mbpSplit{
        flex-direction: column;
    }
    .mbpTitle{
        text-align: center;
    }
}