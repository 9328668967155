.aspContainer{
    /* font-size: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.aspHeader{
    font-size: 50px;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.aspSecondaryContainer{
    width: 90vw;
}

.aspInnerContainer{
    /* border:2px solid #000; */
    /* margin:20px; */
    /* padding: 20px; */
    width: 100%;
    border-radius: 20px;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.aspRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.aspItem{
    border:2px solid #000;
    width: 70vw;
    padding: 10px;
}

.aspMessageItem{
    /* border:2px solid #000; */
    width: 100%;
    padding: 10px;
}

.aspSupportRemarks{
    width: 100%;
}

.aspSupportRemarks{
    width: 100%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}

.supportText{
    font-size: 20px;
    width: 100%;
}

.aspButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}

.aspResolveButton{
    
    border-radius: 10px;
    font-size: 20px;
    background-color: #D5C67A;
    border: none;
    padding: 10px;
    min-width: 300px;
    margin-top: 50px;
}

.aspSelection{
    width: 25%;
    height: 20px;
}

.aspSubDetails{
    display: flex;
    justify-content: right;
    align-content: right;
    padding-top: 30px;
    gap: 20px;
}

.aspLabel{
    font-size: 20px;
}

.aspLabel2{
    font-size: 12px;
}

.aspDescription{
    font-size: 15px;
    width: 100%;
}

@media screen and (max-width:1000px) {
    .aspResolveButton{
        width: 100%;
    }

    .aspRow{
        width: 100%;
        flex-direction: column;
    }
    .aspItem{
        width: 100%;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;   
    }
}