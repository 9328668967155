.edpImageContainer{
    justify-content: center;
    
    height: 500px;
    border-radius: 10px;
    border: 2px solid #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;   
}

.edpEventImage{
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.edpTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.edpImageContainer .edpLabel,.edpUploadContent{
    text-align: center;
}

.edpUploadContent{
    height: 50px;
    border: none;
    display: flex;
    text-align: center;
}

.edpContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.edpInnerContainer{
    padding: 20px;
    justify-content: center;
    width: 90vw;
}
.edpLabel{
    font-size: 20px;
    width: 100%;
}

.edpFieldContainer{
    padding: 10px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}


.edpSplitContainer{
    display: flex;
    margin-top: 30px;
    gap: 50px;
}
.edpSplit{
    align-items: left;
    width: 50%;
    /* padding: 30px; */
}
.edpSplit2{
    align-items: right;
    width: 50%;
    /* padding: 30px; */
}

.edpTypeSelection{
    /* width: 20vw;
    height: 20px;
    margin-top: 6px;
    padding: 20px; */
    font-size: 20px;
}

.edpInput{
    border-radius: 10px;
}

.edpHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#newEventButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 25px;
}

.eventDescription{
    width: 100%;
}

.signupButtonContainer{
    display: flex;
    justify-content: center;
}

@media screen and (max-width:1000px) {
    .edpTitle{
        font-size: 30px;

    }
    .edpSplitContainer{
        flex-direction: column;
    }
    .edpSplit,.aeepSplit2{
        width: 100%;
    }
    .edpFieldContainer{
        padding:0px;
        margin-top: 10px;
        width: 100%;
    }


}