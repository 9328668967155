.errorContainer{
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.errorInnerContainer{
    width: 90vw;
}

.errorInnerContainer h1{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #fff;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.errorInnerContainer p{
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.errorContainer img{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

@media screen and (max-width:1000px) {
    .errorContainer{
        margin-top: 0px;
    }

    .errorInnerContainer p{
        font-size: 15px;
        line-height: normal;
    }
}