a{
  cursor: pointer;
}


.headerImageContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.headerDiv{
  position: absolute;
  /* left: 10px; */
  top: 200px;
  color: white;
  width: 90%;
  /* height: 100px; */
  display: flex;
  justify-content: left;
}

.headerDiv h1{
  font-weight: bold;
  font-size: 50px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  
}

.headerDiv a{
  text-decoration: none;
  color: white;
  background-color: #D5C67A;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 23px;
}

.buttonContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-top: 20px;
}

.headerAbsolute{
  width: 30%;
}

/* header image */
.headerImage{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}


/* Staticstics */

.statistics{
  display:flex;
  flex-direction: row;
  justify-content: center;
  padding: 100px 0px 100px 0px;
  background-color: #E8E3FF;
  gap: 50px;
}

.indyStats{
  /* margin-left: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.indyStats h2{
  text-align: center;
  width: 300px;
}

/* Checkered */
.splitDiv{
  display:flex;
  /* flex-direction: row; */
  background-color: #D9D9D9;
}

.splitDiv img{
  width: 50vw;
}

.splitTextDiv{
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerSplitDiv{
  width: 40vw;
}

.innerSplitDiv h1{
  font-size: 50px;
  font-weight: bold;
  color:white;
  margin: 0px;
}

.innerSplitDiv p{
  font-size: 25px;
  color: white;
  margin-top: 10px;
}

.innerSplitDiv a{
  text-decoration: none;
  color: black;
  background-color: #D5C67A;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 23px;
}



/* Mini FAQ */
.miniFAQ{
  background-color: #E8E3FF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px 50px 0px;
}

.questionButton{
  border: none;
  background-color: #4CB5AE;
  border-radius: 10px;
  justify-content: space-between;
  width: 85vw;
  display: flex;
  align-items: flex-start;
  color: white;
  padding: 5px 20px 5px 20px;
  margin-top: 10px;
  flex-direction: column;
  text-align: left;
}

.innerButtonDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.questionButton p{
  font-size: 20px;
}


/* Question Forms */
.questionForms{
  background-color: #4CB5AE;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.questionForms h1{
  color: white;
}

.forms{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  width: 70vw;
  padding-bottom: 50px;
}

.forms h6{
  width: 35vw;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.innerForms{
  width: 35vw;
}

.innerForms p{
  color: white;
  padding-left: 10px;
}

.innerForms input{
  width: 30vw;
  height: 30px;
  border-radius: 10px;
  border: none;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.innerForms textarea{
  border-radius: 10px;
  border: none;
  width: 30vw;
  padding: 10px;
  resize: none;
}

.innerForms a{
  text-decoration: none;
  color: black;
  background-color: #D5C67A;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 17px;
}

input, textarea:focus{
  outline: none;
}

.checked{
  width: 100vw;
}


@media screen and (max-width: 1000px) {
  .statistics{
    flex-direction: column;
  }

  .splitDiv{
    flex-direction: column;
    flex-flow: wrap;
    width: 100vw;
  }
  .splitDiv img{
    width: 100vw;
  }
  
  .splitTextDiv{
    
    min-width: 100vw;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .forms{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .forms h6{
    width: 80vw;
    text-align: center;
  }

  .forms input{
    width: 80vw;
  }

  .innerForms{
    width:80vw;
  }

  .innerForms textarea{
    width: 80vw;
  }

  .innerSplitDiv{
    min-width: 80vw;
  }

  .buttonContainer{
    flex-direction: column;
    align-items: center;
  }

  #special{
    flex-wrap: wrap-reverse;
  }
}





@media screen and (max-width: 800px) {
  .headerAbsolute{
    top:0px;
    width: 100%;
  }
}

