.mfdSplit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#newBookingButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 25px;
}


.mfdTableHeader{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 25px;
    background-color: #1B4965;
    padding: 10px;
    color: white;
}

.mfdTableHeaderItem{
    /* margin: 0 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.mfdTableRow{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.mfdTableItem{
    /* margin: 0 0px 0px 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.mfdTable{
    margin-bottom: 100px;
}


#mfdViewButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    background-color: #D5C67A ;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
}

#mfdViewButton:hover{
    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.mfdContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mfdInnerContainer{
    width: 90vw;
}

.mfdTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}


.mfdItems{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;
    width: 40vw;
}
.mfdItemTitle{
    font-size: 20px;
}
.mfdItemContent{
    font-size: 20px;
    font-weight: bold;
    border: 2px #000 solid;
    width: 70%;
    padding: 10px;
}

.mfdButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 100px;
}

.mfdButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 25px;
    margin: 4px 2px;
    background-color: #D5C67A ;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    min-width: 350px;
}

.mfdInputContainer {
    width: 100%;
}

textarea{
    width: 100%;
    height: 200px;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid #000;
    border-radius: 4px;
    background-color: #ffffff;
    resize: none;
    font-size: 15px;
}

input{
    width: 100%;
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid #000;
    border-radius: 4px;
    background-color: #ffffff;
    resize: none;
    font-size: 15px;
}
.mfdSplitLeft ,.mfdSplitRight{
    width: 45%;
}


@media screen and (max-width: 1000px) {
    .mfdSplitLeft ,.mfdSplitRight{
        width: 100%;
    }

    .mfdItems{
        flex-direction: column;
        width: 90%;
        align-items: flex-start;
    }

    .mfdSplit{
        flex-direction: column;
    }
    .mfdItemTitle{
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .mfdItems p{
        margin-bottom: 5px;
        margin-top: 0px;
        width: 100%;
    }
    .mfdButtonContainer{
        flex-direction: column;
    }
}
