.aeepImageContainer{
    justify-content: center;
    /* padding: 30px; */
    height: 200px;
    border-radius: 10px;
    border: 2px solid #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;   
}

.aeepTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.aeepImageContainer .aeepLabel,.aeepUploadContent{
    text-align: center;
}

.aeepEventImage{
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.aeepUploadContent{
    height: 50px;
    border: none;
    display: flex;
    text-align: center;
}

.aeepContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aeepInnerContainer{
    padding: 20px;
    justify-content: center;
    width: 90vw;
}
.aeepLabel{
    font-size: 20px;
    width: 100%;
}

.aeepFieldContainer{
    /* padding: 10px; */
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}


.aeepSplitContainer{
    display: flex;
    margin-top: 30px;
    gap: 50px;
}
.aeepSplit{
    align-items: left;
    width: 50%;
    /* padding: 30px; */
}
.aeepSplit2{
    align-items: right;
    width: 50%;
    /* padding: 30px; */
}

.aeepTypeSelection{
    /* width: 20vw;
    height: 20px;
    margin-top: 6px;
    padding: 20px; */
    font-size: 20px;
}

.aeepInput{
    border-radius: 10px;
}

.aeepHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#newEventButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 25px;
}

.eventDescription{
    width: 100%;
}

.aeepTableHeader{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 25px;
    background-color: #1B4965;
    padding: 10px;
    color: white;
}

.aeepTableHeaderItem{
    /* margin: 0 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
    width: 30%;
}

.aeepTableRow{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.aeepTable{
    margin-bottom: 10px;
}

.aeepTableItem{
     /* margin: 0 0px 0px 10px; */
     margin-top: 0px;
     margin-bottom: 0px;
     width: 30%;
}

.aeepSmallTable{
    margin-bottom: 20px;
    width: 90%;
    background-color: #1B4965;
    padding: 20px;
}

.aeepSmallItem{
    color: white;
    font-size: 12px;
}

.aeepSmallRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #1B4965;
}

.aeepSmallTableContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aeepViewButton{
    border: none;
    color: white;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    background-color: red ;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
}

.aeepViewButton:hover{
    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.aeepButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 100px;
}

.aeepButton{
    border: none;
    color: black;
    text-decoration: none;
    font-size: 25px;
    margin: 4px 2px;
    background-color: #D5C67A ;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    min-width: 350px;
}

.aeepDeleteButton{
    border: none;
    color: white;
    text-decoration: none;
    font-size: 25px;
    margin: 4px 2px;
    background-color: red ;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    min-width: 350px;
}

@media screen and (max-width:1000px) {
    .aeepTitle{
        font-size: 30px;
        text-align: center;
    }
    .aeepSplitContainer{
        flex-direction: column;
    }
    .aeepSplit,.aeepSplit2{
        width: 100%;
        flex-direction: column;
    }
    .aeepFieldContainer{
        padding:0px;
        margin-top: 10px;
        width: 100%;
    }
    .aeepTableHeaderItem{
        width: 100%;
    }
    .aeepTableItem{
        width: 100%;
    }
    .aeepTableRow{
        flex-direction: column;
    }
    .aeepActionButton{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
   


}