.mnbpForms{

}

.mnbpFieldContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40vw;
    align-items: center;
}

.mnbpFieldContainer input,.mnbpFieldContainer select{
    width: 30vw;
    /* height: 30px; */
    border: 2px solid #000;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    padding: 30px;
    font-size: 20px;
}

.mnbpSplit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mnbpFieldContainer label,.mnbpLabel{
    font-size: 20px;
}

/* select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #000;
} */

.mnbpBookingRemarks{
    flex-direction: column;
    display: flex;

}

.bookingText{
    font-size: 20px;
    padding: 15px;
    resize: none;
    height: 200px;
    border: 2px solid #000;
    border-radius: 10px;
}

.mnbpButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mnbpButton{
    border: none;
    background-color: #1B4965;
    color: white;
    padding: 20px 50px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 10px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 100px;
}



.mnbpChargesContainer{
    padding: 20px;
    background-color: #1B4965;
    color: white;
    border-radius: 10px;
    margin-bottom: 100px;
}

.mnbpChargesContainer h3{
    font-size: 20px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
}

.mnbpInnerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mnbpCharges{
    width: 70vw;
    background-color: #D9D9D9;
    color: black;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: normal;
    margin-bottom: 25px;
}

#smallCharge{
    font-size: 15px;
}

@media screen and (max-width:1000px) {
    .mnbpFieldContainer{
        width: 100%;
        flex-direction: column;
    }
    .mnbpSplit{
        flex-direction: column;
    }
    select,input,label,.mnbpInput,.mnbpFieldContainer input,.mnbpFieldContainer select{
        width: 90vw;
    }
    .mnbpForms{
        width: 100%;
    }
}