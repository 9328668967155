.mpTitle{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    color: #1B4965;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.mpContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mpInnerContainer{
    width: 90vw;
}



#submitButton{
    text-decoration: none;
    background-color: #D5C67A;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: black;
}

.mpButtonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-top: 70px;
}

.learnToFly{
    margin-top: 100px;
}


.detailsContainer{
    display: flex;
    justify-content: space-around;
}

.detailsContainer2{
    display: flex;
    justify-content: space-around;

}

.mpWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    
}

.mpDetails{
    width: 50vw;
    align-items: center;
}

.mpContactDetails input{
    width: 20vw;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #4cb5ae;
    margin-bottom: 20px;
    margin-top: 0px;
}

.mpContactDetails h4{
    margin-top: 0px;
    margin-bottom: 3px;
    padding-left: 10px;
}

.mpStepsContainer{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    min-width: 500px;
    min-height: 340px;

}

/* .applySPL{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    width: 40vw
}

.rsfcMember{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    width: 40vw
}

.flyingTraining{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    width: 40vw
}

.Exams{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    width: 40vw
}

.flightHandling{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    width: 40vw
}

.restrictedPPL{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    width: 40vw
}


.navTraining{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    width: 40vw
}

.fullPPL{
    text-decoration: none;
    background-color: #4cb5ae;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    color: #ffffff;
    margin-bottom: 70px;
    width: 40vw
} */


#applyButton{
    text-decoration: none;
    background-color: #D5C67A;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 23px;
    margin-bottom: 70px;
    color: black;
    
}

.pplSteps{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: flex-start;
    gap: 10px;
    width: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.pplSteps::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width:1000px) {
    .mpContainer{
        margin-top: 0px;
    }
    .mpTitle{
        font-size: 30px;
        line-height: normal;
    }
    .detailsContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .mpDetails{
        width: 100%;
    }
    .mpTextInput,.mpWrapper,.mpContactDetails{
        width: 100%;
    }
    .mpContactDetails input{
        width: 100%;
    }
    .mpWrapper{
        margin-top: 0px;
    }
    #submitButton{
        margin-top: 0px;
        width: 100%;
        text-align: center;
    }

    .mpButtonContainer{
        margin-top: 0px;
    }

    #applyButton{
        margin-top: 0px;
        width: 90%;
        text-align: center;
    }
    .mpStepsContainer{
        /* width: 50%; */
        min-height: 150px;
    }
    .mpStepsContainer p{
        font-size: 15px;
    }
}

@media screen and (max-width:1000px) {
    .eventHeaderImage{
        width: 100vw;
        height: 300px;
        object-fit: cover;
    }
}