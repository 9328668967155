/* header navigation bar*/

.header{
    /* padding: 10px; */
    background-color: #1B4965;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;
  }
  
  .innerHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90vw;
    justify-content: space-between;
  }
  
  .headerRight Link{
    margin-right: 30px;
    font-family: 'Poppins', sans-serif;
    color: white;
  
  }
  
  .navigationButtons{
    margin-right: 30px;
    font-family: 'Poppins', sans-serif;
    color: white;
    text-decoration: none;
    font-size: 25px;
  }
  
  /* login button */
  .loginbutton{
    /* margin-right: 10px; */
    padding: 10px 30px;
    background-color: #D5C67A;
    border-radius: 10px;
    color: black;
    text-decoration: none;
    font-size: 20px;
  }
  
  
  
  .logo{
    height: 90px;
  }
  


  /* Footer */
.footer{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    background-color: #1B4965;
    padding: 50px 0px 100px 0px;
  }
  
  .footer__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px;
    gap: 30px;
  }
  
  .footer__container h4 {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
  }
  
  .links{
    text-decoration: none;
    color: white;
  }
  
  
  .memberHeaderRight Link{
    margin-right: 30px;
    font-family: 'Poppins', sans-serif;
    color: white;
  }

  .memberHeaderRight{
    display: flex;
    flex-direction:column;
    align-items: right;
    justify-content: right;
  }

  .memberHeaderRight h1 , .memberHeaderRight h2{
    color: white;
    text-align: right;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .memberHeaderRight h1{
    font-size: 30px;
    font-weight: normal;
  }

.navbar{
  background-color: #D9D9D9;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.innerNavbar{
  width: 90vw;
  /* horizontal scroll, no scrollbar visible */
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  /* hide scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 20px 0px;
}

.innerNavbar::-webkit-scrollbar {
  display: none;
}

.navbarItems{
  margin-right: 30px;
  font-family: 'Poppins', sans-serif;
  color: black;
  text-decoration: none;
  font-size: 25px;
  padding: 10px 50px;
  background-color: #1B4965;
  min-width: 350px;
  color: white;
  border-radius: 10px;
  border: 2px solid #1B4965;
}


.mini-header{
  background-color: #1B4965;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.hamburger{
  margin-right: 30px;
}

.mini-nav{
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #1B4965;
  gap: 50px;
  width: 100vw;
  position: fixed;
  padding-bottom: 100px;
}



@media screen and (max-width: 1000px) {
  .footer{
    flex-direction: column;
    align-items: left;
  }
  .footer__container{
    margin-right: 0px;
  }
  .navbarItems{
    font-size: 15px;
    margin-right: 10px;
  }
  .innerNavbar{
    padding: 15px 0px;
  }
  .navbar{
    padding: 5px;
  }
}

.announcement{
  /* modal view */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: white;
}

.announcementText{

  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 25px;
}

.innerAnnouncementDiv{
  background-color: #1B4965;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 70vw;
}

.minimiseButton{
  /* remove default styling */
  background-color: transparent;
  border: none;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.logout{
  /* remove default styling */
  background-color: transparent;
  border: none;
  color: white;
  font-size: 30px;
  font-weight: bold;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.logout p{
  font-size: 15px;
  font-weight: normal;
}

.headerSplit{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  gap: 20px;
}

.username{
  color: white;
  font-size: 15px;
  font-weight: normal;
  margin: 0px;
}

.profileImage{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.Menu {
  position: absolute;
  width: 200px;
  margin-top: 10px;
  background-color: #1B4965;
  padding: 10px;
}

ul{
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

li{
  margin: 10px 0px;
  color: white;
  text-align: center;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */

}

li:hover{
  cursor: pointer;
  text-shadow: 0px 0px 5px black;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
}

.username:hover{
  cursor: pointer;
  text-shadow: 0px 0px 5px black;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
}